<div class="selection-bar">
    <div class="metal-container">
        <div>
            <span
                class="title-underline metal-color"
                (mouseenter)="changeHighlightState(allMetals, true)"
                (mouseleave)="changeHighlightState(allMetals, false)"
                >Metals
            </span>
        </div>
        <div class="buttons">
            <button
                class="alkali-metal color"
                (mouseenter)="changeHighlightState(['alkali'], true)"
                (mouseleave)="changeHighlightState(['alkali'], false)"
                [class.selected]="highlightState.alkali === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.alkali && grayButtonStyle.alkali === true"
            >
                <span>Alkali metals</span>
            </button>
            <button
                class="alkaline-earth-metal color"
                (mouseenter)="changeHighlightState(['alkaline'], true)"
                (mouseleave)="changeHighlightState(['alkaline'], false)"
                [class.selected]="highlightState.alkaline === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.alkaline && grayButtonStyle.alkaline === true"
            >
                <span>Alkaline earth metals</span>
            </button>
            <button
                class="lanthanide color"
                (mouseenter)="changeHighlightState(['lant'], true)"
                (mouseleave)="changeHighlightState(['lant'], false)"
                [class.selected]="highlightState.lant === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.lant && grayButtonStyle.lant === true"
            >
                <span>Lanthanoids</span>
            </button>
            <button
                class="actinide color"
                (mouseenter)="changeHighlightState(['actinoid'], true)"
                (mouseleave)="changeHighlightState(['actinoid'], false)"
                [class.selected]="highlightState.actinoid === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.actinoid && grayButtonStyle.actinoid === true"
            >
                <span>Actinoids</span>
            </button>
            <button
                class="transition-metal color"
                (mouseenter)="changeHighlightState(['transition'], true)"
                (mouseleave)="changeHighlightState(['transition'], false)"
                [class.selected]="highlightState.transition === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.transition && grayButtonStyle.transition === true"
            >
                <span>Transition metals</span>
            </button>
            <button
                class="post-transition-metal color"
                (mouseenter)="changeHighlightState(['postTransition'], true)"
                (mouseleave)="changeHighlightState(['postTransition'], false)"
                [class.selected]="highlightState.postTransition === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.postTransition && grayButtonStyle.postTransition === true"
            >
                <span>Post-transition metals</span>
            </button>
        </div>
    </div>
    <div class="other">
        <p class="title">&nbsp;</p>
        <div class="buttons">
            <button
                class="metalloid color"
                (mouseenter)="changeHighlightState(['metalloid'], true)"
                (mouseleave)="changeHighlightState(['metalloid'], false)"
                [class.selected]="highlightState.metalloid === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.metalloid && grayButtonStyle.metalloid === true"
            >
                <span>Metalloids</span>
            </button>
        </div>
    </div>
    <div class="nonmetal-container">
        <div>
            <span
                class="title-underline metal-color"
                (mouseenter)="changeHighlightState(allNonMetals, true)"
                (mouseleave)="changeHighlightState(allNonMetals, false)"
            >
                Nonmetals
            </span>
        </div>
        <div class="buttons">
            <button
                class="nonmetal color"
                (mouseenter)="changeHighlightState(['nonMetal'], true)"
                (mouseleave)="changeHighlightState(['nonMetal'], false)"
                [class.selected]="highlightState.nonMetal === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.nonMetal && grayButtonStyle.nonMetal === true"
            >
                <span>Other nonmetals</span>
            </button>
            <button
                class="noble-gas color"
                (mouseenter)="changeHighlightState(['nobleGas'], true)"
                (mouseleave)="changeHighlightState(['nobleGas'], false)"
                [class.selected]="highlightState.nobleGas === true && numHighlightState() === 1"
                [class.gray]="grayButtonStyle.nobleGas && grayButtonStyle.nobleGas === true"
            >
                <span>Noble gases</span>
            </button>
        </div>
    </div>
</div>
